import accountSettings from './es-cl/accountSettings.json';
import booking from './es-cl/booking.json';
import chats from './es-cl/chats.json';
import common from './es-cl/common.json';
import faq from './es-cl/faq.json';
import footer from './es-cl/footer.json';
import forms from './es-cl/forms.json';
import login from './es-cl/login.json';
import marketReport from './es-cl/marketReport.json';
import menu from './es-cl/menu.json';
import network from './es-cl/network.json';
import onboarding from './es-cl/onboarding.json';
import organizationWizard from './es-cl/organizationWizard.json';
import productSettings from './es-cl/productSettings.json';
import report from './es-cl/report.json';
import search from './es-cl/search.json';
import terms from './es-cl/terms.json';

const resources = {
  accountSettings,
  booking,
  chats,
  common,
  faq,
  footer,
  forms,
  login,
  marketReport,
  menu,
  network,
  onboarding,
  organizationWizard,
  productSettings,
  report,
  search,
  terms
} as const;

export default resources;
